import React, { Component } from 'react';
const tawkTo = require("tawkto-react");
const tawkToPropertyId = '57d17dd5c465436c8cde287d'

class Chat extends Component {

  componentDidMount(){
    tawkTo(tawkToPropertyId)
  }

  render() {
    return (
      <div>
        
      </div>
    );
  };
};

export default Chat;
