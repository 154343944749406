import './css/App.css';

function Certifications() {
  return (
    <div className="fade-in">
    CERTIFICATIONS
    <div className="about">
      <p>On July 23, 2004, SeaSpace acquired the ISO 9001:2000 certification after a rigorous review of its processes and procedures by NSF International Strategic Registrations, Ltd. The rigorous process of acquiring this certification demonstrates SeaSpace Corporation’s commitment to providing its customers with the highest quality of products and services, with results validated by an ISO accredited registrar. The ISO 9001 inspection process includes 23 different elements, from product design and development to production, inspection and servicing. SeaSpace complied with all ISO 9001 requirements on its first attempt. In 2023, SeaSpace received its latest recertification.</p>
    </div>
    <div className="certs">
      <img className="certsImage" src="images/iso.png" alt="ISO 9001" />
    </div>
  </div>
  );
}

export default Certifications;
