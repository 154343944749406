import React from 'react';

//var apiUrl = 'https://10.0.10.60:3001/support/'; //development
var apiUrl = '/api/';

class Downloads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: '',
      downloads:[],
    }
  }

  componentDidMount() {
    this.getDownloads();
  }

  getDownloads() {
    var get = apiUrl+'downloads'
    fetch(get)
    .then((response) => response.json())
    .then((results) => {
      this.setState({
        downloads: results
      })
    })
    .catch((e) => {
      console.log('Error:', e)
    })
  }

  render() {
    return (
         <div className="support-download-links">
           {this.state.downloads.map(function(links) {
              return (
                  <div className="dashboard-text" key={links.link}><a className="download-text" href={"/downloads/"+links.file}>{links.link}</a><br />{links.description}<br /><br /></div>
              );
           })}
         </div>
      )
   }
}

export default Downloads;
