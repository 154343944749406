import React, { Component, Suspense, lazy } from 'react';
import bcrypt from 'bcryptjs';
import Request from './accountRequest';
import GetCode from './passwordCode';
import Downloads from './supportDownloads';

const Dashboard = lazy(() => import("./supportDashboard"))

//var apiUrl = 'https://10.0.10.60:3001/support/'; //development
var apiUrl = '/api/';

class Support extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: '',
      email: '',
      first: '',
      last: '',
      password: '',
      admin: '',
      errMessage: '',
      active: 0,
      token: '',
      access: false,
      resetPassword: false,
      diffTime: 20,
      supportPage: 'login',
      ip: this.props.ip,
    }
    this.supportPage = this.supportPage.bind(this)
  }

  componentDidMount() {
    let email = localStorage.getItem("email");
    let tokenExpire = localStorage.getItem("tokenExpire");
    if(email && tokenExpire) {
      this.checkTokenTime();
    }
  }

  loginForm = (e) => {
    e.preventDefault();
    var data = new FormData(e.target);
    var email = data.get('email');
    var password = data.get('password');
    var checkUser = apiUrl+'checkUserExist?email='+email
    //console.log(checkUser)
    fetch(checkUser)
    .then((response) => response.json())
    .then((responseJson) => {
      var errMessage = responseJson['result']
      if( errMessage === 0 ) {
        this.setState((prevState, props) => ({
          errMessage: "Email not found."
       }))
     } else {
       var getuser = apiUrl+'getUser?email='+email
       fetch(getuser)
       .then((response) => response.json())
       .then((responseJson) => {
        var userAccess = bcrypt.compareSync(password, responseJson.password)
         if(userAccess) {
           this.setState({
             email: responseJson.email,
             first: responseJson.first,
             last: responseJson.last,
             password: responseJson.password,
             active: responseJson.active,
             admin: responseJson.admin,
             errMessage: '',
             access: true,
             token: responseJson.token,
             supportPage: 'Dashboard',
           })
           if(responseJson.active == 0 ) {
             this.setState((prevState, props) => ({
               errMessage: "Account disabled."
            }))
           }
           localStorage.setItem("email", responseJson.email);
           localStorage.setItem("tokenExpire", responseJson.tokenExpire);
           this.setToken();
         } else {
           this.setState((prevState, props) => ({
             errMessage: "Check password and try again."
          }))
         }
       })
      }
    })
    .catch((e) => {
      console.log('Error:', e)
    })
  }

  setToken = () => {
    var set = apiUrl+'setToken?email='+this.state.email
    fetch(set)
      .then((response) => response.json())
      .then((responseJson) => {
      })
      .catch((e)=>{
        console.log(e)
      })
   }


  checkConnections = () => {
    let check = apiUrl+'getViewed'
    try {
      fetch(check)
      .then((response) => response.json())
      .then((responseJson) => {
        console.log('checkConnections')
      })
    } catch(e) {
      console.log(e)
      //console.log('checkConnections-------')
    }
    return 'checkConnections'
  }

  checkTokenTime() {
    var d1 = new Date(localStorage.getItem("tokenExpire")),
    d2 = new Date();

    // time comparison to set token time and display dash
    var diff = d2 - d1;
    var duration = Math.floor(diff / 60e3)
    //console.log(duration + ' ' + this.state.diffTime)

    if(duration < this.state.diffTime) {
      //console.log("login granted")
      this.setState((prevState, props) => ({
        access: true,
        active: 1,
      }))
    } else {
      localStorage.setItem("tokenExpire","")
    }
  }

  supportPage = (evt) => {
    this.setState((prevState, props) => ({
      supportPage: evt,
    }))
  }


  render() {
    let support
    let supportForm

    if(this.state.supportPage === 'login') {
      supportForm = <div>
      <form onSubmit={this.loginForm}>
      <div className="support-login"> Login </div>
         <div className="loginbar">
            <input className="input" id='email' type='text' name='email' placeholder="Email Address" defaultValue={this.state.email} required />
            <br />
            <input className="input" id='password' type='password' name='password' placeholder="Password" defaultValue={this.state.password} required />
            <br />
         </div>
         <div>
            <p style={{color:"red", fontSize:"1vw"}}>{this.state.errMessage}</p>
         </div>
         <div className="support-signin-div">
          <button className="support-signin"> SIGN IN </button>
        </div>
      </form>
      <div className="request-account" onClick={(evt)=>this.supportPage('request')}>Account Request</div>
      <div className="reset-password" onClick={(evt)=>this.supportPage('reset')}>Reset Password</div>
      </div>
    } //else {
      if(this.state.access == false && this.state.active == 0) {
        support = supportForm
      } else if(this.state.access && this.state.active == 1) {
        support = <Suspense fallback={<div><img src='images/loading.gif' className='loadingPage' width='5%' height='5%' alt='Loading page'/></div>}>
          <Dashboard
            first={this.state.first}
            last={this.state.last}
            email={this.state.email}
            active={this.state.active}
            admin={this.state.admin}
            token={this.state.token}
          />
      </Suspense>
    }

      if(this.state.supportPage === 'request') {
        support = <Request supportPage={this.supportPage} />
      } else if (this.state.supportPage === 'reset') {
        support = <GetCode supportPage={this.supportPage}/>
      } else if (this.state.supportPage === 'download') {
        support = <Downloads supportPage={this.supportPage}/>
      }

      return (
        <div className="fade-in">
         Customer Support
        <div className="support-text"> Currently all inquiries to customer support needs to be made by emailing support@seaspace.com or by calling 858-746-1160.</div>
          <br />
          {support}
        </div>
      )
    }
};


export default Support;
