/*
 *
 * @author Robert Harper, support -at- seaspace.com
 * @version $Id $
 *
 */
/* eslint-disable */
import React from 'react';
import ImageGallery from 'react-image-gallery';
import Footer from './footer';
import images from './data/grb.json';
import 'react-image-gallery/styles/css/image-gallery.css';
import './css/App.css';

class Landing extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        style : {
            width : 0,
        },
      }
  }

  componentDidMount() {

  }

  render() {

    return (
      <div className="fade-in">
        <div className="image-text">GLOBAL LEADERS  <div className="image-text-small"> IN REMOTE SENSING SOLUTIONS</div></div>
        <div className="landing-content"><p>At its inception in 1982, SeaSpace Corporation was a pioneer by providing commercial remote sensing ground stations. When real-time direct-reception data was exclusively available to government agencies, SeaSpace Corporation developed the TeraScan® ground station, which brought a cost-effective solution to the research and industrial sector.  This was the catalyst that launched some of the most important discoveries today involving the ocean, atmosphere and environment. </p>
        <p>With three decades of experience in remote sensing, SeaSpace currently provides solutions to meet the requirements of a variety of users, including research, military and emergency response, and currently has TeraScan® systems in operation at more than 550 customer sites in over 47 countries and all seven continents, receiving and processing data from virtually all meteorological, oceanographic and environmental remote sensing satellites. SeaSpace is dedicated to providing their customers with the most advanced products for satellite remote sensing along with the highest levels of service and support.  </p></div>
        <div className="landing-processed">All products processed with TeraScan® software</div>
        <div className="img-gallery">
          <ImageGallery
            items={images}
            description={images}
            autoPlay={false}
            showNav={true}
            lazyLoad={true}
            slideDuration={1000}
            slideInterval={4000}
            showFullscreenButton={true}
            showPlayButton={false}
            showBullets={false}
            showThumbnails={false}
            slideOnThumbnailOver={false}
            showIndex={false}
            startIndex={0}
          />
        </div>
      </div>
    );
  }
}

export default Landing;
