import React from 'react';
import * as EmailValidator from 'email-validator';

var apiUrl = '/api/';
//var apiUrl = 'https://10.0.10.60:3001/#/api'; //development

class Antennas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: '',
      value: '',
      brochures: false,
      name: '',
      email: '',
      comments: '',
      company: '',
      ran1: 0,
      ran2: 1,
      captchaResults: '',
      emailValidated: false,
      emailError: '',
      ip: this.props.ip,
    }
    this.brochureForm = this.brochureForm.bind(this);
  }

  componentDidMount() {
    this.captcha();
    /*setInterval(() => {
      localStorage.setItem("antennas", false)
    },1200000); //1200000*/

  }

  // display flyers
  showBrochues = () => {
    if(!this.state.brochures) {
      this.setState((prevState, props) => ({
        brochures: true,
      }));
    } else if(this.state.brochures) {
      this.setState((prevState, props) => ({
        brochures: false,
      }));
    }
    this.off();
  }

  sendEmail(email,company,comments) {
    var send = apiUrl+'productEmailer?email='+email+'&company='+company+'&comments='+comments+'&product=antennas&ip='+this.state.ip
    fetch(send)
  }

  // captcha ovelay toggle
  on() {
    document.getElementById("overlay").style.display = "inline-block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  // captcha for viewing products (software/antennas)
  captcha() {
    var ran1 = Math.floor(Math.random() * 11);
    var ran2 = Math.floor(Math.random() * 15);
    var captcha = ran1+ran2

    this.setState({
      ran1: ran1,
      ran2: ran2,
      captchaResults: captcha,
    })
  }

  // log products views
  brochureForm = (e) => {
    e.preventDefault();

    var data = new FormData(e.target);
    var email = data.get('email');
    var company = data.get('company');
    var comments = data.get('comments');
    var captchaResults = data.get('captcha')
    var check = EmailValidator.validate(email);

      if (check === true) {
      this.setState((prevState, props) => ({
          emailValidated: true,
          email: email,
          company: company,
          comments: comments,
          emailError: ''
        }))
        if(this.state.captchaResults != captchaResults) {
          this.setState((prevState, props) => ({
            emailError: "Captcha does not match.",
            captchaResults: ''
          }))
          this.captcha();
        } else {
          localStorage.setItem("antennas", true);
          this.showBrochues();
          this.sendEmail(email,company,comments)
        }
      } else {
        this.setState((prevState, props) => ({
          emailValidated: false,
          emailError: "Email address is not valid.",
          captchaResults: ''
        }))
        this.captcha();
      }
  }

  // log viewed products
  viewed = (e) => {
    var viewed = apiUrl+'insertProductViewed/?email='+this.state.email+'&product='+e+'&ip='+this.state.ip+'&company='+this.state.company
    fetch(viewed)
  }

  render() {
    //console.log(this.state.timeleft)

    let antennaProducts;
    let geo;
    let geosr;
    let polar15;
    let polar24;
    let shipboard15;
    let shipboard24;
    var overlay;
    var showBrochues;
    let countDown;

    if(!this.state.brochues) {
      showBrochues = <div className="showBrochues" onClick={this.on}>Click here to learn more</div>
    }

    var viewAntennas = localStorage.getItem("antennas")

    // dislplay document links after form submitted
    if(this.state.brochures || viewAntennas === "true") {
      //set expire 10 mins
      setInterval(() => {
        localStorage.setItem("antennas", false)
      },600000);

      geo = <a className="antenna" href="docs/GEO.pdf" target="_blank" rel="noreferrer">View</a>
      polar15 = <a className="antenna" href="docs/Polar_Shipboard.pdf" target="_blank" rel="noreferrer">View</a>
      shipboard15 = <a className="antenna" href="docs/Polar_Shipboard.pdf" target="_blank" rel="noreferrer">View</a>
      antennaProducts = "antenna-products-brochures"
      showBrochues = <div className="fadeout"><p>Access to documents will expire in 10 minutes</p></div>
    } else {
      antennaProducts = "antenna-products"
    }

    return (
      <div className="fade-in">
      <br />
      Ground and Shipboard Antennas
        <div className="antennas">
          <p>SeaSpace has the complete solution for your satellite ground system requirements. Our vertically integrated product family will meet your needs.</p>
          <p>TeraScan® Satellite ground systems can receive data from multiple platforms such as GRB, MTG, COMS, Himawari, FY, NOAA, DMSP, MetOp, MetOp-SG, EOS (Aqua/Terra), JPSS, and others upon request.</p>
        </div>

        <div className={antennaProducts}>
          <div className="geo" >
              <div className="geo-image"><img src="images/geo-antenna.png" height="80%" width="80%" alt="GEOSTATIONARY" title="View Brochure"/></div>
              <p className="antenna-title">GEOSTATIONARY GROUND STATIONS</p>
              <p className="antenna-text">For 24/7 weather and environmental monitoring anywhere on the globe, TeraScan® ground systems continuously receive, process, archive and distribute data from every geosynchronous direct-readout satellite.</p>
              <p className="antenna-brochure" title="Download" onClick={(e)=>this.viewed('GEO')}>{geo}</p>
              <br />
              <p className="antenna-brochure" title="Download" onClick={(e)=>this.viewed('GOSE-R')}>{geosr}</p>
          </div>
          <div className="polar">
              <div className="polar-image"><img className="pointer" src="images/polar.png" height="80%" width="80%" alt="POLAR-ORBITAL"/></div>
              <p className="antenna-title">POLAR-ORBITAL GROUND STATIONS</p>
              <p className="antenna-text">From weather, oceanography, environmental studies, to disaster management, TeraScan® X, L, and S Band Ground Stations are the complete acquisition, data processing, and product interrogation solution for every major direct broadcast polar orbiting satellite.</p>
              <p className="antenna-brochure" title="Download" onClick={(e)=>this.viewed('1.5m Polar')}>{polar15}</p>
              <br />
              <p className="antenna-brochure" title="Download" onClick={(e)=>this.viewed('2.4m Polar')}>{polar24}</p>
          </div>
          <div className="shipboard">
            <div className="shipboard-image"><img className="pointer" src="images/shipboard.png" height="80%" width="80%" alt="SHIPBOARD"/></div>
              <p className="antenna-title">SHIPBOARD STATIONS</p>
              <p className="antenna-text-shipboard">WDS, The Weather Decision Support System (WDS) produces local and hemispheric weather images vessels using a shipboard TeraScan® meteorological and environmental station.</p>
              <p className="antenna-brochure" title="Download" onClick={(e)=>this.viewed('Shipboard 1.5m antenna')}>{shipboard15}</p>
              <br />
              <p className="antenna-brochure" title="Download" onClick={(e)=>this.viewed('Shipboard 2.4m antenna')}>{shipboard24}</p>
          </div>

          <div id="overlay">
            <div className="closeOverlay" onClick={this.off}>X</div>
            <div id="textOverlay">
              <div className="overlayTitle">Enter email to view information.</div>
            <form onSubmit={this.brochureForm}>
               <div>
                    <div className="email"><input id='email' type='text' name='email' defaultValue={this.state.email} placeholder="EMAIL" required/></div>
                    <br />
                    <div className="company"><input id="company" type="text" name="company" defaultValue={this.state.company} placeholder="INSTITUTION" required/></div>
                    <br />
                    <div className="comments"><textarea id='comments' type='text' name='comments' rows="4" cols="40" defaultValue={this.state.comments} placeholder="COMMENTS"/></div>
                  <br />
                  <br />
                  {this.state.ran1} + {this.state.ran2} = <input type="text" name="captcha" defaultValue='' size='1' required />
                  <br />
                  <br />
                  <button className='sendForm'> Access </button>
                </div>
                <div>
                <p style={{color:"red"}}>{this.state.emailError}</p>
                </div>
            </form>
            </div>
          </div>
          {showBrochues}
        </div>
      </div>
    );
  }
}

export default Antennas;
