import React from 'react';
import bcrypt from 'bcryptjs';

//var apiUrl = 'https://10.0.10.60:3001/support/'; //development
var apiUrl = '/api/';

class GetCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: '',
      email: '',
      errMessage: '',
      emailExist: 0,
      resetPassword: false,
      code: 0,
      login: false,
    }
  }
  componentWillReceiveProps(newProps) {
    this.setState((prevState, props) => ({
      page: 'support',
    }))
 }

  requestForm = (e) => {
    e.preventDefault();
    var data = new FormData(e.target)
    var email = data.get('email')

    var checkUser = apiUrl+'checkUserExist?email='+email
    console.debug(checkUser)
    fetch(checkUser)
    .then((response) => response.json())
    .then((check) => {
      try {
        this.setState((prevState, props) => ({
          email: email,
        }))
        console.log('Results: '+JSON.stringify(check.result))
        var checkUserExist = check.result

        if(checkUserExist == 1) {
          this.setState((prevState, props) => ({
            errMessage: ''
          }))
          var getCode = apiUrl+'getResetCode?email='+email
          fetch(getCode)
          .then((response) => response.json())
          .then((result) => {
              this.setState((prevState, props) => ({
                code: result.code,
              }))
              if(this.state.code != 0) {
                console.debug('testing...')
              }
            })
            .catch((err) => {
              console.debug(err)
            })
          }
          else {
            this.setState((prevState, props) => ({
              errMessage: 'Invalid email.'
            }))
          }
      }
      catch (error) {
          console.log('Error parsing JSON:', error, data);
      }
    })
  }

  verifyCode = (e) => {
    e.preventDefault();

    var data = new FormData(e.target)
    var code = data.get('code')

    //console.log(code + ' ' + this.state.code)
    if(code == this.state.code) {
      this.setState((prevState, props) => ({
        resetPassword: true
      }))
    } else {
      this.setState((prevState, props) => ({
        errMessage: 'Invalid code.'
      }))
    }
  }

  setPassword = (e) => {
    var salt = bcrypt.genSaltSync(10);
    e.preventDefault();

    var data = new FormData(e.target)
    var password = data.get('password')
    var verifyPassword = data.get('verifyPassword')

    if(password === verifyPassword) {
      var hash = bcrypt.hashSync(password, salt);

      var setPassword = apiUrl+'updatePassword?email='+this.state.email+'&password='+hash
      fetch(setPassword)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result)
        this.setState((prevState, props) => ({
          page: 'support',
          login: true
        }))
      })

    } else {
      this.setState((prevState, props) => ({
        errMessage: 'Password does not match.'
      }))
    }
  }

  render() {
    //console.log('Support Page: '+this.state.page)

    let getPage
    let send

    if(!this.state.login){
      send = <button className="support-signin"> UPDATE </button>
    } else {
      send = <button className="support-signin" onClick={this.props.supportPage}> LOGIN </button>
    }

    if(this.state.resetPassword) {
      getPage = <form onSubmit={this.setPassword}>
      <div className="support-login"> Reset Password </div>
         <div className="loginbar">
         <input className="input" type='password' name='password' placeholder="Password" defaultValue='' required /><br />
         <input className="input" type='password' name='verifyPassword' placeholder="Verify Password" defaultValue='' required /><br />
          </div>
          <div>
          <p style={{color:"red", fontSize:"1vw"}}>{this.state.errMessage}</p>
          </div>
          {send}
      </form>
    }
    else if(this.state.code != 0) {
      getPage = <form onSubmit={this.verifyCode}>
      <div className="support-login"> Enter Email </div>
         <div className="loginbar">
         <input className="input" id='email' type='text' name='email' placeholder="Email Address" defaultValue={this.state.email} required /><br />
         <input className="input" type='number' name='code' placeholder="Enter Code" defaultValue='' required />
          </div>
          <div>
          <p style={{color:"red", fontSize:"1vw"}}>{this.state.errMessage}</p>
          </div>
          <button className="support-signin"> SEND </button>
      </form>
    }
    else if(!this.state.resetPassword) {
      getPage = <div>
      <form onSubmit={this.requestForm}>
      <div className="support-login"> Enter Code </div>
         <div className="loginbar">
         <input className="input" id='email' type='text' name='email' placeholder="Email Address" defaultValue={this.state.email} required /><br />
          </div>
          <div>
          <p style={{color:"red", fontSize:"1vw"}}>{this.state.errMessage}</p>
          </div>
          <button className="support-signin"> SEND </button>
      </form>
      <div className="bottom-nav">
        <div className="request-account" onClick={() => this.props.supportPage('login')}>Back to Login</div>
        <div className="reset-password" onClick={() => this.props.supportPage('request')}>Request Account</div>
      </div>
      </div>
    }

    return (
      <div className="fade-in">
      {getPage}
      </div>
    )
  }
};


export default GetCode;
