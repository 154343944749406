import './css/App.css';

var host = 'https://'+window.location.hostname

function Partners() {
  return (
    <div className="fade-in">
    Partners
    <div className="partners">
      <iframe src={host+"/partners"} frameBorder="0" title="Partners" />
    </div>
    </div>
  );
}

export default Partners;
