import './css/App.css';

function About() {
  return (
    <div className="fade-in">
      DECADES OF EXPERIENCE IN REMOTE SENSING
      <div className="about">
        <p>At its inception in 1982, SeaSpace Corporation was a pioneer by providing commercial remote sensing ground stations. When real-time direct-reception data was exclusively available to government agencies, SeaSpace Corporation developed the TeraScan® ground station, which brought a cost-effective solution to the research and industrial sector. This was the catalyst that launched some of the most important discoveries today involving the ocean, atmosphere and environment.
        </p>
        <p>With three decades of experience in remote sensing, SeaSpace currently provides solutions to meet the requirements of a variety of users, including research, military and emergency response, and currently has TeraScan® systems in operation at more than 550 customer sites in over 47 countries and all seven continents, receiving and processing data from virtually all meteorological, oceanographic and environmental remote sensing satellites. SeaSpace is dedicated to providing their customers with the most advanced products for satellite remote sensing along with the highest levels of service and support.</p>
        <p>Our unique knowledge and understanding of the applications and science of remote sensing enables SeaSpace to provide the most powerful and versatile products available. Indeed, no other company in the world is more committed to the advancement of the science and practice of earth remote sensing.</p>

      </div>
    HISTORY
    <div className="history">
      <p>SeaSpace Corporation has been leading the remote sensing ground station business for 30 years. We have installed more than 550 ground stations and processing systems in over 47 countries. SeaSpace pioneered low-cost satellite ground station technology. SeaSpace personnel are subject matter experts in the fields of meteorological, oceanographic, and environmental remote sensing.</p>
      <p>SeaSpace is carrying its commitment to excellence into the future. SeaSpace technology delivers the best solutions for monitoring weather and environmental management. For research, environmental management, satellite operations, or defense needs, SeaSpace has the answer for you. From Alaska to Chile, Bahrain to the Antarctic, SeaSpace delivers the Earth, On Demand.</p>

      {/*<dl>
        <dt>1982</dt>
        <dd>- Founded</dd>
        <dt>1986</dt>
        <dd>- First low-cost remote sensing satellite ground stations</dd>
        <dt>1987</dt>
        <dd>- First installation in Antarctica to support flight operations</dd>
        <dt>1990</dt>
        <dd>- First Fully-transportable system for US Air Force Operation Desert Storm</dd>
        <dd>- First commercially available shipboard systems</dd>
        <dd>- First release of TeraScan software</dd>
        <dt>1993</dt>
        <dd>- First annual global user conference</dd>
        <dd>- First ground-based and weather radar installations</dd>
        <dt>1995</dt>
        <dd>- TeraVision™ image processing and analysis software released</dd>
        <dt>1999</dt>
        <dd>- First low-cost COTS X-Band system</dd>
        <dt>2000</dt>
        <dd>- First to provide Oceansat reception and processing</dd>
        <dt>2004</dt>
        <dd>- First commercial cluster-based, multithreaded processing of MODIS data</dd>
        <dd>- ISO 9001:2000 certification</dd>
        <dt>2005</dt>
        <dd>- Introduction of AXYOMTM antenna technology</dd>
        <dt>2007</dt>
        <dd>- SeaSpace is acquired by Sunghong, South Korea</dd>
        <dt>2008</dt>
        <dd>- CE Certification of 2.4m X/L-Band System</dd>
        <dd>- 50th MODIS acquisition and processing system sold</dd>
        <dt>2009</dt>
        <dd>- Reintroduction of 60cm antenna</dd>
        <dt>2010</dt>
        <dd>- Opened new, expanded headquarters</dd>
        <dd>- Introduction of next-generation web-based products with release of TeraCatTM and StormTrackerTM</dd>
        <dd>- SeaSpace purchased remote sensing solutions division of Vexcel</dd>
        <dt>2012</dt><img className="about-image" src="images/seaspace-seal.png" title="seal" alt="Corporate Seal"/>
        <dd>- SeaSpace celebrates 30 year anniversary</dd>
        <dd>- SeaSpace acquires and processes first direct broadcast image from NPP VIIRS of California</dd>
        <dt>2015</dt>
        <dd>- SeaSpace acquires and processes first direct broadcast image from Japanese HimawariCast transmission</dd>
        <br />
        <br />
        <br />
      </dl>*/}
    </div>
  </div>
  );
}

export default About;
