import React from 'react';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';

import publicIp from 'public-ip';
import Landing from './landing';
import About from './about';
import Antennas from './products/antennas';
import Software from './products/software';
import Partners from './partners';
import Certifications from './certs';
import Contact from './contact';
import Support from './support/support';
import Chat from './chat';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: '',
      menuChange: false,
      page: 'home',
      access: this.props.access,
      supportPage: 'login',
      ip: '',
      label: '',
      email: '',
    }
  }

  componentDidMount() {
    (async () => {
      try{
        var ip = await publicIp.v4()
        this.setState((prevState, props) => ({
          ip: ip,
        }));
      } catch(e) {
          console.log(e)
      }
    })();
  }

  menu = () => {
    if(!this.state.menuChange) {
      this.setState((prevState, props) => ({
        menuChange: true,
      }));
    } else if(this.state.menuChange) {
      this.setState((prevState, props) => ({
        menuChange: false,
      }));
    }
  }

  label = (e) => {
    this.setState((prevState, props) => ({
      label: e,
    }));
  }

  newPage = (e) => {
    //console.log(e)
    if(e === 'support') {
      this.setState((prevState, props) => ({
        page: e,
        supportPage: 'login',
      }))
    } else {
      this.setState((prevState, props) => ({
        page: e,
      }));
    }
    this.menu();
  }


  render() {
    //console.log(this.state.email)

    var d = new Date();
    var n = d.getFullYear();

    let ondemand;
    let menu;
    let homeActive = "menu-span";
    let aboutActive = "menu-span";
    let antennasActive = "menu-span";
    let softwareActive = "menu-span";
    let historyActive = "menu-span";
    let certsActive = "menu-span";
    let partnersActive = "menu-span";
    let supportActive = "menu-span";
    let contactActive = "menu-span";

    // menu selection
    if(this.state.page === "contact") {
      contactActive = "menu-span active"
    }
    if(this.state.page === "support") {
      supportActive = "menu-span active"
    }
    if(this.state.page === "certs") {
      certsActive = "menu-span active"
    }
    if(this.state.page === "partners") {
      partnersActive = "menu-span active"
    }
    if(this.state.page === "antennas") {
      antennasActive = "menu-span active"
    }
    if(this.state.page === "software") {
      softwareActive = "menu-span active"
    }
    if(this.state.page === "about") {
      aboutActive = "menu-span active"
    }
    if(this.state.page === "home") {
      homeActive = "menu-span active"
    }

    let homeLabel;
    let aboutLabel;
    let antennasLabel;
    let softwareLabel;
    let certLabel;
    let partnersLabel;
    let supportLabel;
    let contactLabel;

    // highlight active page
    if(this.state.label === "Home") {
      //console.log(this.state.label)
      homeLabel = <div className="menu-label">{this.state.label}</div>
    } else if(this.state.label === "About") {
      //console.log(this.state.label)
      aboutLabel = <div className="menu-label">{this.state.label}</div>
    } else if(this.state.label === "Software") {
      //console.log(this.state.label)
      softwareLabel = <div className="menu-label">{this.state.label}</div>
    } else if(this.state.label === "Antennas") {
      //console.log(this.state.label)
      antennasLabel = <div className="menu-label">{this.state.label}</div>
    } else if(this.state.label === "Partners") {
      //console.log(this.state.label)
      partnersLabel = <div className="menu-label">{this.state.label}</div>
    } else if(this.state.label === "Certifications") {
      //console.log(this.state.label)
      certLabel = <div className="menu-label">{this.state.label}</div>
    } else if(this.state.label === "Support") {
      //console.log(this.state.label)
      supportLabel = <div className="menu-label">{this.state.label}</div>
    } else if(this.state.label === "Contact") {
      //console.log(this.state.label)
      contactLabel = <div className="menu-label">{this.state.label}</div>
    }

    ondemand = <div className="header-text">EARTH ON DEMAND</div>

    // image icons
    menu = <div className="menu" onClick={this.menu}>
      <img className="logo" src="images/seaspace.png" />
      <Link to="/contact"><div className={contactActive} onClick={()=>this.newPage("contact")} ><img className="menu-icons" src="images/contact.png" alt="Contact" title="Contact" onMouseEnter={(e) => this.label("Contact")} onMouseLeave={() => this.label("")}/>{contactLabel}</div></Link>
      <Link to="/support"><div className={supportActive} onClick={()=>this.newPage("support")} ><img className="menu-icons" src="images/support.png" alt="Support" title="Support" onMouseEnter={(e) => this.label("Support")} onMouseLeave={() => this.label("")}/>{supportLabel}</div></Link>
      <Link to="/certs"><div className={certsActive} onClick={()=>this.newPage("certs")} ><img className="menu-icons" src="images/certs.png" alt="Certifications" title="Certifications" onMouseEnter={(e) => this.label("Certifications")} onMouseLeave={() => this.label("")}/>{certLabel}</div></Link>
      <Link to="/partners"><div className={partnersActive} onClick={()=>this.newPage("partners")} ><img className="menu-icons" src="images/partners.png" alt="Partners" title="Partners" onMouseEnter={(e) => this.label("Partners")} onMouseLeave={() => this.label("")}/>{partnersLabel}</div></Link>
      <Link to="/software"><div className={softwareActive} onClick={()=>this.newPage("software")} ><img className="menu-icons" src="images/software.png" alt="Software" title="Software" onMouseEnter={(e) => this.label("Software")} onMouseLeave={() => this.label("")}/>{softwareLabel}</div></Link>
      <Link to="/antennas"><div className={antennasActive} onClick={()=>this.newPage("antennas")} ><img className="menu-icons" src="images/antenna.png" alt="Antennas" title="Antennas" onMouseEnter={(e) => this.label("Antennas")} onMouseLeave={() => this.label("")}/>{antennasLabel}</div></Link>
      <Link to="/about"><div className={aboutActive} onClick={()=>this.newPage("about")}><img className="menu-icons" src="images/about.png" alt="About" title="About" onMouseEnter={(e) => this.label("About")} onMouseLeave={() => this.label("")}/>{aboutLabel}</div></Link>
      <Link to="/"><div className={homeActive} onClick={()=>this.newPage("home")} ><img className="menu-icons" src="images/home.png" alt="Home" title="Home" onMouseEnter={(e) => this.label("Home")} onMouseLeave={() => this.label("")}/>{homeLabel}</div></Link>
    </div>

    let topImage = "images/landing.png";
    let imageText;
    let home;
    let about;
    let antennas;
    let software;
    let certs;
    let partners;
    let support;
    let contact;
    let page;

    // menu page and image import
    if( this.state.page === "home") {
      page = <Landing />
      //imageText = <div className="image-text">GLOBAL LEADERS  <div className="image-text-small"> IN REMOTE SENSING SOLUTIONS</div></div>
    } else if( this.state.page === "about") {
      page = <About />
    } else if( this.state.page === "antennas") {
      topImage = "images/antennas_top.jpg"
      imageText = <div className="image-landing-div"><img className="landing-text" src="images/satellites-img-text.png" alt="header"/></div>
      page = <Antennas ip={this.state.ip} />
    } else if( this.state.page === "software") {
      topImage = "images/products_top_image.jpg"
      page = <Software ip={this.state.ip} />
    } else if( this.state.page === "certs") {
      page = <div className="fade-in">
        <Certifications />
      </div>
    } else if( this.state.page === "partners") {
      page = <div className="fade-in">
        <Partners />
      </div>
    } else if( this.state.page === "support") {
      topImage = "images/top_image3.jpg"
      page = <div className="fade-in">
        <Support
          supportPage='login'
          page='support'
          ip={this.state.ip}
        />
      </div>
    } else if( this.state.page === "contact") {
      page = <div className="fade-in">
        <Contact />
      </div>
    } else if( this.state.page === "careers") {
      page = <div className="fade-in">
        Careers
      </div>
    }

    return (
      <div className="main-container" alt="logo-div">
        {ondemand}
        {menu}
        <div className="col-6 col-s-6 fadi-in">
          <img className="top-image fadi-in" src={topImage} alt="header"/>
          {imageText}
        </div>
        <div className="row">
        <div className="col-1 col-s-1">
          <main>
              <Switch>
                  <Route path="/" component={Landing} exact />
                  <Route path="/about" component={About} />
                  <Route path="/antennas" component={Antennas} />
                  <Route path="/software" component={Software} />
                  <Route path="/partners" component={Partners} />
                  <Route path="/certs" component={Certifications} />
                  <Route path="/support" component={Support} />
                  <Route path="/contact" component={Contact} />
                  <Route component={Error} />
              </Switch>
          </main>
        </div>
        </div>
        <div className="col-11 col-s-11 ">
        <Chat />
        </div>
        <div className="footer">
           Copyright © {n} SEASPACE Corporation. All rights reserved.
           9155 Brown Deer Road, Suite 7, San Diego, CA 92121 U.S.A | Telephone: +1 858.746.1100 | Fax: +1 858.746.1199
        </div>
      </div>
    )
  }
}


export default App;
