import React from 'react';
import './css/App.css';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      show: false,
      to: '',
      subject: '',
      message: '',
    };
  }

getCode() {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < 7; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

    this.setState((prevState, props) => ({
      code: text,
    }));
}

  show = (e) => {
    this.setState((prevState, props) => ({
      show: true,
      to: e
    }));
    this.getCode()
  }

  hide() {
    if(this.state.show) {
      this.setState((prevState, props) => ({
        show: false,
        to: ''
      }));
    }
  }

  mailer = (e) => {
    e.preventDefault();

    var data = new FormData(e.target)
    var name = data.get('name')
    var company = data.get('company')
    var email = data.get('email')
    var subject = data.get('subject')
    var message = data.get('message')
    var codeVerify = data.get('codeVerify')
    var to = this.state.to+'@seaspace.com'

    this.setState((prevState, props) => ({
      name: name,
      company: company,
      email: email,
      subject: subject,
      message: message
    }));

    console.log(to)
    console.log(name)
    console.log(email)
    console.log(subject)
    console.log(company)
    console.log(subject)
    console.log(message)
    console.log(codeVerify)

    if(this.state.code !== codeVerify) {
      this.setState((prevState, props) => ({
        errMessage: 'CODE DOES NOT MATCH.',
      }))
      this.getCode()
    } else {
      this.setState((prevState, props) => ({
        errMessage: '',
      }))
      this.hide();
    }


    //this.hide();
  }

  render() {
    //console.log(this.state.code)

    let show
    let title

    if(this.state.to === 'sales') {
      title = 'Email Sales'
    }
    if(this.state.to === 'support') {
      title = 'Email Support'
    }

    if(this.state.show) {
      show = <div className="contact-mailer">{title}
      <form onSubmit={this.mailer}>
        <br />
          <input className="contact-subject" type='text' name='name' placeholder="NAME" defaultValue='' required />
          <br />
          <input className="contact-subject" type='text' name='company' placeholder="COMPANY" defaultValue='' required />
          <br />
          <input className="contact-subject" type='text' name='email' placeholder="EMAIL" defaultValue='' required />
          <br />
          <br />
          <input className="contact-subject" type='text' name='subject' placeholder="SUBJECT" defaultValue='' required />
          <br />
          <br />
          <textarea className="contact-message" name='message' rows="5" cols="40" placeholder="Enter Message to Sales" defaultValue='' required />
          <br />
          <div className="loginbar">
           <div className="unselectable">{this.state.code}</div>
          <input className="contact-code" type='text' name='codeVerify' placeholder="Enter Code" defaultValue='' size="9" required />
          <input type="submit" className="contact-send" name="SEND" value="SEND" />
        </div>
      </form>
      <div>
      <p style={{marginTop:"2vw", color:"red", fontSize:"1vw"}}>{this.state.errMessage}</p>
      </div>
      </div>
    }

    return (
      <div className="fade-in">
      Contact Information<br /><br />
        <div className="contact">
        <iframe
        src="https://www.openstreetmap.org/export/embed.html?bbox=-117.35080718994142%2C32.726064576130646%2C-117.00714111328126%2C33.0509755807245&amp;layer=mapnik&amp;marker=32.888669008998114%2C-117.17897415161133"
        title="Contact Information"
        frameBorder="0" />
        </div>
        <div className="contact-info">
          <p> Address:</p>
          <p>9155 Brown Deer Road <br />
          Suite 7 <br />
          San Diego, CA 92121, U.S.A<br />
          </p>
          <hr className="contact-hr" />

          <p> Phone:</p>
          <p>Telephone: +1 858.746.1100 <br />
          Fax: +1 858.746.1199<br />
          Customer Service: +1 858.746.1160<br />
          </p>
          <hr className="contact-hr" />

          <p> Email:</p>
          <div className="sales" onClick={(e) => this.show('sales')}>Sales: sales@SeaSpace.com</div>
          <div className="sales" onClick={(e) => this.show('support')}>Support: support@SeaSpace.com</div>
          {show}
        </div>
      </div>
    );
  }
}

export default Contact;
