import React from 'react';
import bcrypt from 'bcryptjs';

//var apiUrl = 'https://10.0.10.60:3001/support/'; //development
var apiUrl = '/api/';

class Request extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: '',
      email: '',
      first: '',
      last: '',
      institution: '',
      errMessage: '',
      emailExist: 0,
      supportPage: this.props.supportPage
    }
  }

  requestForm = (e) => {
    e.preventDefault();

    var data = new FormData(e.target)
    var first = data.get('first')
    var last = data.get('last')
    var email = data.get('email')
    var institution = data.get('institution')
    var password = data.get('password')
    var verifyPassword = data.get('verifyPassword')

    //console.debug(email)

    var salt = bcrypt.genSaltSync(10);

    var checkUser = apiUrl+'checkUserExist?email='+email
    //console.debug(checkUser)
    fetch(checkUser)
    .then((response) => response.json())
    .then((check) => {
      //console.log('Results: '+JSON.stringify(check.result))
      this.setState((prevState, props) => ({
        emailExist: check.result
      }))

      if(!this.state.emailExist) {
        if(password == verifyPassword) {
          var hash = bcrypt.hashSync(password, salt);
          var adduser = apiUrl+'addUser/?first='+first+'&last='+last+'&email='+email+'&password='+hash+'&company='+institution
          //console.debug(adduser)
          fetch(adduser)
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson.message)
            if(responseJson.message === 'added') {
              var errMessage = 'Support will review your request and notify you by email.'
              this.reset(errMessage, first, last, email, institution, password, verifyPassword)
            }
          })
          .catch((e))
        } else {
          this.setState((prevState, props) => ({
            errMessage: 'Verify password incorrect.'
          }))
        }
      } else {
        this.setState((prevState, props) => ({
          errMessage: 'Email exist.'
        }))
      }
   })
  }

  reset = (errMessage) => {
    this.setState({
      errMessage: errMessage,
    })
    setTimeout(function () {
      window.location.reload();
    }, 3000);
  }


  render() {
    //console.log(this.state.supportPage)

      return (
        <div className="fade-in">
        <form onSubmit={this.requestForm}>
        <div className="support-login"> Account Request </div>
          <div className="loginbar">
            <input className="input" id='first' type='text' name='first' placeholder="First" defaultValue={this.state.first} required /><br />
            <input className="input" id='last' type='text' name='last' placeholder="Last" defaultValue={this.state.last} required /><br />
            <input className="input" id='email' type='text' name='email' placeholder="Email Address" defaultValue={this.state.email} required /><br />
            <input className="input" id='institution' type='text' name='institution' placeholder="Institution" defaultValue={this.state.institution} required /><br />
            <input className="input" id='password' type='password' name='password' placeholder="Password" defaultValue={this.state.password} required /><br />
            <input className="input" id='password' type='password' name='verifyPassword' placeholder="Verify Password" defaultValue={this.state.verifyPassword} required /><br />
          </div>
            <div>
              <p style={{color:"red", fontSize:"1vw"}}>{this.state.errMessage}</p>
            </div>
            <button className="support-signin"> SEND </button>
        </form>
        <div className="bottom-nav">
          <div className="request-account" onClick={() => this.props.supportPage('login')}>Back to Login</div>
          <div className="reset-password" onClick={() => this.props.supportPage('reset')}>Reset Password</div>
        </div>
        </div>
      )
    }
};


export default Request;
