import './css/App.css';

var d = new Date();
var n = d.getFullYear();


function Footer() {

  return (
    <div className="footer">
       Copyright © {n} SEASPACE Corporation. All rights reserved.
       9155 Brown Deer Road, Suite 7, San Diego, CA 92121 U.S.A | Telephone: +1 858.746.1100 | Fax: +1 858.746.1199
    </div>
  );
}

export default Footer;
