import React from 'react';
import * as EmailValidator from 'email-validator';

var apiUrl = '/api/';
//var apiUrl = 'https://10.0.10.60:3001/#/api'; //development

class Software extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: '',
      value: '',
      brochures: false,
      name: '',
      email: '',
      company: '',
      comments: '',
      ran1: 0,
      ran2: 1,
      captchaResults: '',
      emailValidated: false,
      emailError: '',
      ip: this.props.ip,
    }
    this.brochureForm = this.brochureForm.bind(this);
  }

  // run when page initially load
  componentDidMount() {
    this.captcha();
    //Check flyer access every
    /*setInterval(() => {
      localStorage.setItem("software", false)
    },300000); //1200000*/
  }

  // show flyer toggle
  showBrochues = () => {
    if(!this.state.brochures) {
      this.setState((prevState, props) => ({
        brochures: true,
      }));
    } else if(this.state.brochures) {
      this.setState((prevState, props) => ({
        brochures: false,
      }));
    }
    this.off();
  }

  sendEmail(email,company,comments,product) {
    var send = apiUrl+'productEmailer/?email='+email+'&company='+company+'&comments='+comments+'&product='+product+'&ip='+this.state.ip
    fetch(send)
  }

  // captcha overlay toggle
  on() {
    document.getElementById("overlay").style.display = "inline-block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  captcha() {
    var ran1 = Math.floor(Math.random() * 11);
    var ran2 = Math.floor(Math.random() * 9);
    var captcha = ran1+ran2

    this.setState({
      ran1: ran1,
      ran2: ran2,
      captchaResults: captcha,
    })
  }

  brochureForm = (e) => {
    e.preventDefault();

    var data = new FormData(e.target);
    var email = data.get('email');
    var company = data.get('company');
    var comments = data.get('comments');
    var captchaResults = data.get('captcha')
    var check = EmailValidator.validate(email);

      if (check === true) {
      this.setState((prevState, props) => ({
          emailValidated: true,
          email: email,
          company: company,
          comments: comments,
          emailError: ''
        }))
        if(this.state.captchaResults != captchaResults) {
          this.setState((prevState, props) => ({
            emailError: "Captcha does not match.",
            captchaResults: ''
          }))
          this.captcha();
        } else {
          localStorage.setItem("software", true);
          this.showBrochues();
          this.sendEmail(email,company,comments)
        }
      } else {
        this.setState((prevState, props) => ({
          emailValidated: false,
          emailError: "Email address is not valid.",
          captchaResults: ''
        }))
        this.captcha();
      }
  }

  // views logged and emailed
  viewed = (e) => {
    var viewed = apiUrl+'insertProductViewed/?email='+this.state.email+'&product='+e+'&ip='+this.state.ip+'&company='+this.state.company
    fetch(viewed)
    let product = e
    let email = this.state.email
    let company = this.state.company
    let comments = this.state.comments
  }

  render() {

    let softwareProducts;
    let terascan;
    let jpss;
    let vulcan;
    let cloud3d;
    let trex;
    let goes;
    let himawari;
    var showBrochues;

    if(!this.state.brochues) {
      showBrochues = <div className="showSoftware" onClick={this.on}>Click here to learn more</div>
    }

    var viewSoftware = localStorage.getItem("software")

    if(this.state.brochures || viewSoftware === "true") {
      //set to expire 10 mins
      setInterval(() => {
        localStorage.setItem("software", false)
      },600000);

      terascan = <a className="software-link" href="./docs/Terascan.pdf" target="_blank" rel="noreferrer" onClick={(e)=>this.viewed('TeraScan')}>View</a>
      jpss = <a className="software-link" href="docs/JPSS.pdf" target="_blank" rel="noreferrer" onClick={(e)=>this.viewed('NPP')}>View</a>
      vulcan = <a className="software-link" href="docs/Vulcan.pdf" target="_blank" rel="noreferrer" onClick={(e)=>this.viewed('Vulcan')}>View</a>
      cloud3d = <a className="software-link" href="docs/Cloud3D.pdf" target="_blank" rel="noreferrer" onClick={(e)=>this.viewed('Cloud')}>View</a>
      trex = <a className="software-link" href="docs/TREX.pdf" target="_blank" rel="noreferrer" onClick={(e)=>this.viewed('TREX')}>View</a>
      goes = <a className="software-link" href="docs/GRB.pdf" target="_blank" rel="noreferrer" onClick={(e)=>this.viewed('GOES')}>View</a>
      himawari = <a className="software-link" href="docs/Himawari.pdf" target="_blank" rel="noreferrer" onClick={(e)=>this.viewed('Himawari')}>View</a>
      softwareProducts = "software-products"
      showBrochues = <div className="fadeout"><br /><br /><p>Access to documents will expire in 10 minutes</p></div>
    } else {
      softwareProducts = "software-products"
    }

    return (
      <div className="fade-in">
      <br />
      TeraScan® Acquisition Processing and Visualization Software
        <div className="software">
          <p>SeaSpace TeraScan® satellite processing and control systems are end-to-end solutions for receiving, processing, archiving, and distributing satellite data.
          <br />
          <br />
          TeraScan® is a powerful, adaptable tool that meets all your needs for any direct-broadcast satellite mission. TeraScan® provides products and add-on options for a complete remote sensing solution.</p>
        </div>
        <br />
        <br />

        <div className={softwareProducts}>
          <div className="software-box" >
            <p className="swproduct-title">TeraScan®</p>
            <img className="software-image" src="images/terascan.png" alt="TeraScan" title="TeraScan"/>
            <br />
            <br />
            <br />
            {terascan}
            <br />
            <br />
            <p className="software-text">TeraScan’s open architecture and script-driven operation give you the power and flexibility to create the processing environment you need. Whether it is data acquisition, sensor conversion, algorithm generation, visualization, or data export and distribution, TeraScan® software suite empowers you.</p>
          </div>

          <div className="software-box">
            <p className="swproduct-title">JPSS Processing</p>
            <img className="software-image" src="images/terascannpp.jpg" alt="JPSS"/>
            <br />
            <br />
            <br />
            {jpss}
            <br />
            <br />
            <p className="software-text">TeraScan® JPSS is an end-to-end environmental data processing solution that automatically processes large EOS datasets from raw to level 2 products. TeraScan® for JPSS supports processing of raw data acquired from any direct broadcast ground station or can use level 1 calibrated SDR data from the NOAA CLASS archives.</p>
          </div>

          <div className="software-box">
            <p className="swproduct-title">TeraVision™</p>
            <img className="software-image" src="images/teravision.png" alt="TeraVision"/>
            <br />
            <br />
            <br />
            <p className="software-text">TeraVision™ is the main tool for visualization in the TeraScan® software package. It enables the user to perform a wide range of image manipulations including enhancements, color adjustments, overlays with coastlines, political boundaries and much more.</p>
          </div>

          <div className="software-box">
            <p className="swproduct-title">Vulcan</p>
            <img className="software-image" src="images/hotspot.png" alt="Vulcan"/>
            <br />
            <br />
            <br />
            {vulcan}
            <br />
            <br />
            <p className="software-text">TeraScan® Vulcan software is a sophisticated software program that detects and monitors wildfires. Vulcan downloads real-time high-resolution data directly from satellites and processes the data into maps that show the location intensity of wildfires.</p>
          </div>

          <div className="software-box" >
            <p className="swproduct-title">Cloud 3D</p>
            <img className="software-image" src="images/cloud.jpg" alt="Cloud3D" title="Cloud3D"/>
            <br />
            <br />
            <br />
            {cloud3d}
            <br />
            <br />
            <p className="software-text">TeraScan® Cloud3D™ is a visualization tool that allows users to display cloud-top data over an earth-surface background in 3D. It enhances the user’s ability to grasp complex atmospheric phenomena by providing a way to interact with the data in three dimensions. Atmospheric stability is dependent on the vertical as well as horizontal structure.</p>
          </div>

          <div className="software-box">
            <p className="swproduct-title">T-REX™</p>
            <img className="software-image" src="images/t-rex.jpg" alt="T_REX"/>
            <br />
            <br />
            <br />
            {trex}
            <br />
            <br />
            <p className="software-text">The TeraScan® Rapid Environmental X-treme processor (T-REX™) for extreme processing requirements for GRB, JPSS and NPP.  T-REX reduces the amount of time by 60-80 percent to process these large datasets that would otherwise take two to three hours for a single pass.</p>
          </div>

          <div className="software-box">
            <p className="swproduct-title">GRB</p>
            <img className="software-image" src="images/grb-dashboard.png" alt="GRB"/>
            <br />
            <br />
            <br />
            {goes}
            <br />
            <br />
            <p className="software-text">The Geostationary Operational Environmental Satellite (GOES) – R Series is the nation’s most advanced fleet of geostationary weather satellites. The GOES-R Series significantly improves the detection and observation of environmental phenomena that directly affect public safety, protection of property and our nation’s economic health and prosperity.</p>
          </div>

          <div className="software-box">
            <p className="swproduct-title">Himawari</p>
            <img className="software-image" src="images/himawari.png" alt="Himawari"/>
            <br />
            <br />
            <br />
            {himawari}
            <br />
            <br />
            <p className="software-text">The HimawariCast system is configured to work with a 2.4m parabolic reflector antenna. The system uses a DVB-S2 receiver for real-time reception from Himawari-8. The system is built with proven TeraScan Software for automated reception, processing, and visualization.</p>
          </div>

          <div id="overlay">
            <div className="closeOverlay" onClick={this.off}>X</div>
            <div id="textOverlay" className="brochureOverlay">
              <p className="overlayTitle">Enter email to view information.</p>
            <form onSubmit={this.brochureForm}>
               <div>
                  <div className="email"><input id="email" type="text" name="email" defaultValue={this.state.email} placeholder="EMAIL" required/></div>
                  <br />
                  <div className="company"><input id="company" type="text" name="company" defaultValue={this.state.company} placeholder="INSTITUTION" required/></div>
                  <br />
                  <div className="email"><textarea id="comments" type="text" name="comments" rows="4" cols="40" defaultValue={this.state.comments} placeholder="COMMENTS"/></div>
                  <br />
                  <br />
                  {this.state.ran1} + {this.state.ran2} = <input type="text" name="captcha" defaultValue='' size='1' required />
                  <br />
                  <br />
                  <br />
                  <button className='sendForm'> View </button>
                </div>
                <div>
                <p style={{color:"red"}}>{this.state.emailError}</p>
                </div>
            </form>
            </div>
          </div>
          {showBrochues}
        </div>
      </div>
    );
  }
}

export default Software;
